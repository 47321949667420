import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { format } from "date-fns";
import Modal from "../../../../_artifact/components/Modal";
import ErrorMessage from "../../../../_artifact/components/forms/ErrorMessage";
import FormField from "../../../../_artifact/components/forms/FormField";
import SubmitButton from "../../../../_artifact/components/forms/SubmitButton";
import * as auth from "../_redux/authRedux";
import Loader from "../../../../_artifact/components/Loader";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import Refresh from "./Refresh";
import PasswordField from "../../../../_artifact/components/forms/PasswordField";
import { trackingLogged } from "../../Tracking/_redux/tracking";
import axios from "axios";
import { setLanguage } from "../../../../_artifact/i18n";

const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const dispatch = useDispatch();
  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );
  const [loading, setLoading] = useState(false);
  const [alertlogin, setAlertlogin] =useState(false);
  const [alertdescription, setAlertdescription]=useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required Field"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required Field"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleSubmit = (values, { setStatus, setSubmitting }) => {
    enableLoading();

    const { email, password } = values;
    auth
      .login(jsonHelper.jsonToForm({ email, password }))
      .then(async ({ data }) => {
        // disableLoading();
        localStorage.setItem("email", email);
        dispatch(auth.actions.login(data));
        const body = {
          ...trackingPayload,
          panelist_email_id: email,
          logged_in_time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          at_homepage: 1,
        };
        dispatch(trackingLogged({ body, update: true }));
        await track(body);
        localStorage.setItem("loginTime", new Date().toISOString());
        localStorage.setItem("tok", data.id_token);
        setLanguage(data.data.language);
      })
      .catch((error) => {
        disableLoading();
        if (error.message === "Network Error")
          retrySubmit(values, { setStatus, setSubmitting });
        else {
          setAlertlogin(true);
          setAlertdescription(error.response.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const retrySubmit = (values, { setStatus, setSubmitting }) => {
    enableLoading();

    const { email, password } = values;
    auth
      .login(jsonHelper.jsonToForm({ email, password }))
      .then(async ({ data }) => {
        // disableLoading();
        localStorage.setItem("email", email);
        dispatch(auth.actions.login(data));
        const body = {
          ...trackingPayload,
          panelist_email_id: email,
          logged_in_time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          at_homepage: 1,
        };
        dispatch(trackingLogged({ body, update: true }));
        await track(body);
        localStorage.setItem("loginTime", new Date().toISOString());
        localStorage.setItem("tok", data.id_token);
        setLanguage(data.data.language);
      })
      .catch((error) => {
        disableLoading();
        if (error.message === "Network Error")
          toast.error("Failed to logging in");
        else{
          setAlertlogin(true);
          setAlertdescription(error.response.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const track = async (body) => {
    await axios
      .post("panelist/logspanelists/", body)
      .then(() => {})
      .catch((error) => {
        console.log(error,"error");
      });
  };
  return (
    <div className="auth-wrap">
      {/* <Link to="/methodologist" className="icon-admin">
        <img src="/assets/images/icon-admin.svg" />
      </Link> */}
      {loading && <Loader />}
      <Refresh />
      <div className="auth-box">
        <div className="auth-logo text-center">
          {/* <img src="/assets/images/logo.png" alt="" /> */}
          <img src="/assets/images/cih_logo.png" width="136" alt="" />
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={LoginSchema}
        >
          {({ status }) => (
            <form className="form-wrap">
              <div className="form-body">
                <ErrorMessage error={status} visible={status} />
                <div className="form-group">
                  <FormField
                    name="email"
                    label="User ID"
                    placeholder="Enter ID"
                  />
                </div>
                <div className="form-group">
                  <PasswordField
                    name="password"
                    label="Password"
                    placeholder="Enter Password"
                  />
                </div>
                <div className="forgot-password text-right">
                  <Link to="/auth/forgot-password" className="link">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="form-footer">
                <SubmitButton title="Login" disabled={loading} />
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Modal
        isOpen={alertlogin}
        action={"OK"}
        title={alertdescription}
        onClose={() => {
          setAlertlogin(false);
          setAlertdescription('');
        }}
      />
    </div>
  );
}

export default Login;
